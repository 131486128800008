/**
 * Box, generic content box style
 *
 */
.box {
  margin-right: -$grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width;
  margin-left: -$grid-gutter-width / 2;

  @media (min-width: $screen-sm-min) {
    // background: $box-bg;
    padding: $box-gutter;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

    &.box-secondary {
      background: $box-bg-secondary;
    }
  }

  /**
   * Boxes inside sidebar
   */
  .sidebar & {
    margin-bottom: $box-gutter;

    > h3 {
      text-align: center;
    }
  }

  /**
   * Box title
   *
   * The color is not set here so that the .box module is more flexible.
   * Use the bootstrap class to give colors or diferrent alignments to
   * the box titles (use classes such as: `text-primary`, `text-right`)
   */
  > h1,
  > h2,
  > h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    margin: 0 0 $box-title--margin-bottom;
    padding-bottom: $box-gutter / 2;
    border-bottom: 1px solid #eee;
    font-size: 18px;

    @media (max-width: $screen-xs-max) {
      padding: 0 $box-gutter ($box-gutter / 2) $box-gutter;
      text-align: center !important;
    }
  }

  /**
   * Readmore
   */
  .readmore,
  .readmore-toggle {
    background: $box-bg;

    @media (max-width: $screen-xs-max) {
      padding: ($box-gutter / 2) $box-gutter;
    }
  }

  // The readmore gradient becomes white when inside the box module
  .readmore[data-readmore]:after {
    background-image: linear-gradient(to bottom, rgba($box-bg,0) 0%, rgba($box-bg,1) 100%);
  }

  /**
   * Special box styling (i.e. Payments, Parteners, etc.)
   */
  &.only-images {
    img {
      margin: 0 5px 5px;
    }
  }

  /**
   * Box content (not always needed to apply this class)
   */
  &-content {
    background: $box-bg;

    @media (max-width: $screen-xs-max) {
      padding: $box-gutter;
      margin-top: -$box-gutter / 2;
    }
  }

  /**
   * Call us box
   */
  &--call_us {
    .box-row {
      position: relative;
      padding-left: 40px;

      @media (max-width: $grid-float-breakpoint) {
        margin-left: $box-gutter;
        margin-right: $box-gutter;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -12px;
      font-size: 24px;
      color: $brand-accent;
    }
    // a {
    //   font-weight:bold;
    //   color: #222;
    // }
    p {
      margin: 0
    }
    .col-xs-4 {
      text-align: center;
    }
  }
}

/**
 * There is alredy margin but the .col should have `clearfix`
 * This fixes the space between `Latest stuff` boxes
 */
@media (max-width: $screen-xs-max) {
  .col-sm-6 + .col-sm-6 {
    .box > h3 {
        padding: $box-gutter;
    }
  }
}

/**
 * Add some padding to introduction block (is not inside a `.box`)
 */
.container > .breath-md:last-child,
.col-md-9 > .breath-md:last-child {
  padding: 0 ($box-gutter / 2) $box-gutter;

  @media (min-width: $grid-float-breakpoint) {
    padding: 0 $box-gutter $box-gutter;
  }
}

