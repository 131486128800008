/**
 * Static content pages style tweaks
 *
 */
.news-overview {
  margin: 0;
  padding: 0;

  .box {
    list-style: none;
  }

  .news-title {
    font-style: italic;
  }

  .news-date {
    text-transform: uppercase;
    color: lighten($gray-light, 5%);
    text-align: center;
    margin-bottom: -$box-gutter / 1.25;
    display: block;

    @media (min-width: $screen-sm-min) {
      float: right;
      padding-left: 10px;
      display: inline;
      margin: 0;
      text-align: right;
    }
  }

  /**
   * Bigger clickable area on mobile, adjust spacing in the bottom
   * accordingly
   */
  p {
    margin: 0 0 10px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }

  .link-readmore {
    padding-left: 5px;
    @media (max-width: $screen-xs-max) {
      display: block;
      float: right;
      padding: 10px;
    }
  }
}
