/**
 * Special media object for tickets list
 *
 */
#tickets-list {
  overflow: auto;
  overflow-x: hidden;

  @media (min-width: $screen-md-min) {
    padding-right: $grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
  }
}

.ticket {

  &-list {
    margin: -$box-title--margin-bottom 0 0;
    padding: 0;

    &-item {
      list-style: none;
      border-bottom: 1px solid #eee;
      background: $box-bg;
      text-transform: uppercase;
      padding: ($box-gutter / 2) $box-gutter;

      &:hover {
        background: darken($box-bg, 0.7%);
      }

      &:last-child {
        border-bottom: 0;
      }

      @media (min-width: $grid-float-breakpoint) {
        padding: $box-gutter / 2 0;
      }

      /**
       * Left side
       */
      &--left {}

      /**
       * Right side
       */
      &--right {}
    }
  }

  /**
   * Section / Row block
   */
  &-row {}

  &-heading {
    float: left;
    font-weight: bold;
    min-width: $ticket-row-min-width;

    // this in case we wanna one single row also on screen-xs
    // display: none;

    // @media (min-width: $screen-xs-min) {
    //   display: block;
    // }
  }
  &-info {
    position: relative;
    display: block;
    padding-left: $ticket-row-min-width;

    &:before {
      content: ": ";
      position: absolute;
      top: 0;
      left: $ticket-row-min-width;
      margin-left: -6px;
    }
  }

  &-data {
    overflow: hidden;
  }

  &-moreinfo {
    float: right;

    > a {
      display: block;
      padding: 6px 0 0 $box-gutter / 2;
      font-size: 15px;
      color: #999;

      &:focus,
      &:hover {
        color: #222;
        text-decoration: none;
      }
    }

    .icon {
      font-size: 16px;
      padding-right: 2px;
    }
  }

  /**
   * Quantity block
   */
  &-quantity {
    overflow: hidden;
    padding-right: $box-gutter / 2;
  }

  /**
   * Price block
   */
  &-price {
    float: right;
    padding: 0 $box-gutter / 2;
    text-align: right;

    > h4 {
      margin: 0;
      font-weight: 100;
      color: $brand-success;
      font-size: 22px;
    }

    span {
      padding-left: 2px;
    }

    > h5 {
      margin: 0;
      font-weight: 100;
    }
  }

  /**
   * Action block
   */
  &-action {
    float: right;

    > .btn {
      padding: $box-gutter / 2.75 $box-gutter / 1.75;
    }
  }
}