/**
 * Box pagination
 */
.box-pagination {
  text-align: center;

  .pagination {
    margin: 5px 0;

    > li > a {
      margin: 0 5px;
      padding: 5px 10px
    }
  }
}
