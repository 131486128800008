/**
 * Box list (shared style)
 */
.box-list {
  margin: 0;
  padding: 0;

  li {
    list-style: none;

    > a {
      border-bottom: 1px solid #eee;
    }

    &:last-child > a {
      border-bottom: 0;
    }

    @media (max-width: $screen-xs-max) {
      background: $box-bg;

      > a {
        padding-left: $box-gutter;
        padding-right: $box-gutter;
      }
    }

    @media (min-width: $screen-sm-min) {
      &:hover {
        margin-right: -$box-gutter;
        margin-left: -$box-gutter;
        > a {
          padding-left: $box-gutter;
          padding-right: $box-gutter;
        }
      }
    }
  }

  /**
   * Box features list
   *
   */
  &--features {
    li {
      position: relative;
      margin: ($box-gutter / 2) 0 !important;
      border: 0;
      padding-left: 23px;
    }
    .icon {
      @extend %round_checkmark;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }

  /**
   * Box no link list (transactions)
   *
   */
  &--no-links {
    margin-top: -$box-title--margin-bottom;
    margin-bottom: -($box-gutter * 0.75);

    > li {
      border-bottom: 1px solid #eee;
      padding: ($box-gutter / 2) 0;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }

      @media (max-width: $screen-xs-max) {
        padding-left: $box-gutter;
        padding-right: $box-gutter;
      }
    }
  }

  /**
   * Box news list
   *
   */
  &--news {
    margin-top: -$box-title--margin-bottom;
    margin-bottom: -($box-gutter * 0.75);

    a {
      display: block;
      padding: ($box-gutter / 2) 0;
      color: $text-color;

      // reset clickable area
      &:focus,
      &:active,
      &:hover {
        text-decoration: none;
      }

      &:hover {
        background: darken($box-bg-secondary, 2%);

        .news-title {
          text-decoration: underline;
        }
      }
    }
  }

  .news-title {
    color: $text-color;
    font-family: $font-family-base;
    font-weight: normal;
    font-style: italic;
    font-size: $font-size-base;
    text-transform: none;
    margin: 0 0 2px;
  }
  .news-date {
    color: lighten($text-color, 20%);
    font-size: 11px;
  }

  /**
   * Box links list
   *
   */
  &--links {
    margin-top: -$box-title--margin-bottom;
    margin-bottom: -$box-gutter;

    > li {
      position: relative;
    }

    a {
      display: block;
      padding: 0;
      height: $box-list-item--height;
      line-height: $box-list-item--height;
      color: $text-color;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.more {
        font-style: italic;
        color: lighten($text-color, 30%);
        text-transform: lowercase;
      }

      &:hover {
        background: darken($box-bg, 1%);

        .box-secondary & {
          background: darken($box-bg-secondary, 1%);
        }

        img {
          right: $box-gutter / 2
        }
      }
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    img {
      position: absolute;
      top: 0;
      right: $box-gutter / 2;
      height: 40px;
      margin-top: 2px;

      & + span {
        padding-right: 30px;
      }

      @media (min-width: $screen-sm-min) {
        right: -$box-gutter / 2;
      }

      &:after {
        content: "";
        width: 40px;
        float: right;
        display: block;
        height: 40px;
      }
    }
  }
}
