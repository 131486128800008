/**
 * Match: Box variation for match page
 *
 */
.form-purchase {
  /**
   * Slight variation to box default style
   */
  .box {
    @media (max-width: $screen-sm-max) {
      padding: $box-gutter;
      margin-bottom: 0;
      background: $box-bg;
    }
  }

  .box-header--title {
    padding-top: $box-gutter;
    margin-top: 0;
  }

  .checkbox > label > label {
    padding: 0;
  }
}
