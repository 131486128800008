/**
 * Purchase: Box variation for purchase page
 *
 */

.page--purchase {

  .box-header {
    padding: 0 $box-gutter;

    @media (min-width: $grid-float-breakpoint) {
    }
  }
}
