/**
 * Special media object for events list
 * used for now only in the most popular tickets block in the homepage,
 * but easily reusable with the following markup:
 *
 * ```
 * ol.event-list
 *   li.event-list-item
 *     div.event-date
 *       div.month Mar
 *       div.day 9
 *       div.year 2015
 *     div.event-info
 *       h5 Some text
 *       h4 Some more important text
 *       span Some tinier text
 *     div.event-action
 *       a.btn.btn-lg.btn-success(href="#") An action
 * ```
 */
.event {
  &-list {
    margin: -$box-title--margin-bottom 0 0;
    padding: 0;

    &-item {
      list-style: none;
      border-bottom: 1px solid #eee;
      background: $box-bg;

      &-inner {
        display: table;
        width: 100%;
      }

      &:hover {
        background: darken($box-bg, 0.7%);
        cursor: pointer;

        & .media-date {
          background: darken($box-bg, 4%);
        }

        & .media-info h4 {
          text-decoration: underline;
        }
      }
    }
  }

  /**
   * Date block
   */
  &-date {
    display: table-cell;
    vertical-align: middle;
    width: 80px;
    padding: 0 0 0 $grid-gutter-width;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $screen-sm) {
      padding: 0 ($grid-gutter-width / 2) 0 0;
    }

    &-inner {
      display: inline-block;
      width: 80px;
      height: 80px;
      padding-top: 11px;
      border-radius: 200px;
      background: #F6F6F6; // darken($box-bg, 2%);
    }

    .month {
      font-weight: bold;
    }

    .day {
      font-weight: bold;
      font-size: 30px;
      margin: -($grid-gutter-width / 2) 0;
    }

    .year {
      font-size: 11px;
    }
  }

  /**
   * Info block
   */
  &-info {
    display: table-cell;
    vertical-align: middle;
    padding: $grid-gutter-width;

    h5 {
      margin: 0;
    }
    h4 {
      margin: 5px 0 1px;
      font-size: 19px;
      font-weight: bold;
    }

    a {
      color: $text-color;
      text-decoration: underline;
    }
    a:focus,
    a:hover {
      text-decoration: underline;
      color: $link-hover-color;
    }
  }

  /**
   * Action block
   */
  &-action {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    text-align: right;
  }
}