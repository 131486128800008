/**
 * Orange round full checkmark
 */
%round_checkmark {
  display: inline-block;
  margin-right: $box-gutter / 2.5;
  padding: 3px;
  border-radius: 30px;
  background: $checkmarks--bg;
  color: $checkmarks--color;
  text-align: center;
  font-size: 9px;
}

/**
 * Highlight style
 *
 */
a.highlight {
  color: $text-color;
  &:hover {
    color: $text-color;
  }
}

.highlight {
  font-weight: bold;

  &.highlight-md {
    font-size: 15px;
  }
  &.highlight-lg {
    font-size: 18px;
  }
}

/**
 * All the checkmarks are green!
 */
.icon-checkmark {
  color: $brand-success;
}

/**
 * More spacing in long texts
 */
.readmore p {
  line-height: 1.8; // $line-height-base * 1.2;
}

/**
 * Breath: vertical spacing utilities
 */
.breath {
  .form-group & {
    margin: 7px 0;
  }
}

.breath-xs {
  margin-top: $grid-gutter-width / 4;
  margin-bottom: $grid-gutter-width / 4;

  &-top {
    margin-top: $grid-gutter-width / 4;
  }
  &-bottom {
    margin-bottom: $grid-gutter-width / 4;
  }
}

.breath-sm {
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;

  &-top {
    margin-top: $grid-gutter-width / 2;
  }
  &-bottom {
    margin-bottom: $grid-gutter-width / 2;
  }
}

.breath-md {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;

  &-top {
    margin-top: $grid-gutter-width;
  }
  &-bottom {
    margin-bottom: $grid-gutter-width;
  }
}

.breath-lg {
  margin-top: $grid-gutter-width * 1.5;
  margin-bottom: $grid-gutter-width * 1.5;

  &-top {
    margin-top: $grid-gutter-width * 1.5;
  }
  &-bottom {
    margin-bottom: $grid-gutter-width * 1.5;
  }
}

.fullwidth {
  width: 100%;
}

.midpoint {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;

  &-inner {
    display: table-cell;
    vertical-align: middle;
  }
}

/**
 * Readmore content has smaller headlines
 */
.readmore {
  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 16px;
  }
}
