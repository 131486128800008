/**
 * Header style
 *
 */
.header {
  margin-bottom: $grid-gutter-width;
}

@import "header-top";
@import "header-main";
@import "header-navbar";
@import "header-info";

/**
 * Animated header elements transitions and defualt states
 */
.sticky-header {
  transform: translateZ(0); // chrome bug fix: http://stackoverflow.com/a/15203880/1938970
}
.sticky-header-clone {
  display: none;
}

@media (min-width: $screen-md-min) {
  .header-main {
    transition: padding .18s ease;
  }
  .logo-img {
    transition: height .18s ease, width .18s ease;
    height: 36px;
  }

  /**
   * Animated header elements on header fixed
   */
  .sticky-header-clone {
    display: block;
    z-index: -99999;
    visibility: hidden;
    position: absolute;
  }

  /**
   * What happens when header is sticked
   */
  .sticky-header-on {

    .sticky-header {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: $zindex-navbar;
    }

    .sticky-header-clone {
      position: static;
    }

    .header-main {
      padding: 0;

      .search {
        margin-top: 9px;
      }
    }

    .logo-img {
      height: 25px;
      margin: 15px 0 2px;
    }
  }
}
