/**
 * Header main (logo, search)
 *
 */
.header-middle,
.header-main {
  position: relative;
  z-index: $zindex-navbar + 10;
  transform: translateZ(0); // chrome bug fix: http://stackoverflow.com/a/15203880/1938970
}

.header-main {
  background: $header-main--bg;

  .logo {
    display: block;
    text-align: center;

    &-title {
      margin: 13.33333333px 0;
    }

    &-subtitle {
      margin: 0;
    }
  }

  /**
   * Search input special style
   */
  #search-main {
    background: #868686;
    color: #ccc;
    border-color: $header-main--bg;

    &:focus {
      box-shadow: none;
    }

    &::placeholder { color: #ccc }
    &::-webkit-input-placeholder { color: #ccc }
    &:-moz-placeholder { color: #ccc }
    &::-moz-placeholder { color: #ccc }
    &:-ms-input-placeholder { color: #ccc }
  }

  @media (min-width: $screen-sm-min) {
    padding: ($grid-gutter-width / 2) 0;

    .search {
      margin-top: 12px;

      .icon {
        padding-right: 5px;
      }
    }

    .twitter-typeahead {
      display: table-cell;
      position: relative;
      z-index: 2;
      float: left;
      width: 100%;
      margin-bottom: 0;
    }
  }

  @media (max-width: $screen-xs-max) {
    .logo {
      max-width: 180px;
      margin: 0 auto;
      text-align: center;
    }

    .twitter-typeahead {
      width: 100%;
    }

    .search {
      margin: 0 -10px;
      padding: 0 10px 10px;
    }

    #search-main {
      padding-left: 50px;
      border: 0;
      color: #fff;
      border-radius: 50px;
      right: 0;
    }

    .input-group {
      width: 100%;
      left: 0;
    }

    .input-group-btn {
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 100%;

     .btn {
        background: 0 0;
        padding: 10px 10px 10px 20px;
        border: 0;
      }
    }
  }
}
