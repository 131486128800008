/**
 * Footer style
 *
 */
$footer-bg: #424242;
$footer-color: #fff;
$footer-lower-bg: #77BD00;
$footer-lower-color: #fefefe;

.footer {
  background: $footer-bg;
  color: $footer-color;
  padding: $grid-gutter-width * 3 $grid-gutter-width $grid-gutter-width;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    color: $footer-color;
  }

  &-lower {
    background: $footer-lower-bg;
    color: $footer-lower-color;
    padding: ($grid-gutter-width * 1.5) $grid-gutter-width;

    a {
      color: $footer-lower-color;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: lighten($footer-lower-color, 10%);
      }
    }

    > p {
        max-width: 566px;
        margin: 0 auto;
    }
  }
}