/**
 * Breadcrumbs (little tweaks to Bootstrap defaults)
 *
 */
.breadcrumb {
  padding: 0 0 ($grid-gutter-width / 2) 0;
  margin-bottom: 0;

  @media (max-width: $screen-sm-max) {
    margin-bottom: $grid-gutter-width;
    margin-top: -$grid-gutter-width / 2;
  }


  a {
    color: lighten($text-color, 20%);
    text-transform: uppercase;
    font-size: 12px;
  }

  > li {
    + li:before {
      font-size: 8px;
      padding: 0 8px;
      color: $breadcrumb-color;
      display: inline-block;
      margin-top: -5px;
      vertical-align: middle;
    }
  }
}