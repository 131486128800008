/**
 * Header info, it display some catchy datas basically
 *
 */
.header-info {
  background: $header-info--bg;
  text-align: center;
  text-transform: uppercase;
  padding: 14px 0;

  .breadcrumb {
    padding: 0;
    margin: 0 !important;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;

    @media (min-width: $screen-sm) {
      padding-right: $grid-gutter-width;
      text-align: left;
    }
  }

  .wrap {
    display: none;

    @media (min-width: $screen-sm) {
      display: block;
      float: left;
      margin-top: -4px;

      &-fb {
        margin-top: -3px;
      }
    }

    &-verified {}

    &-mcafee {
      img {
        max-height: 23px;
        width: auto;
      }
    }

    &-fb {
      margin: 3px 0 0 18px;
    }
  }

  .list-contacts {
    margin: 0;
  }
}
