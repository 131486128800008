/**
 * Banner
 *
 */

#header-banner {
  position: relative;

  // banner loader placeholder
  &-placeholder {
    padding-bottom: 17.938%; // the banner proportion
    position: relative;

    .stretcher {
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    > img {
      width: 100%;
      height: auto;
    }
  }

  /**
   * Banner overlay text common class
   */
  .banner-overlay-text {
    @media (min-width: $screen-md) {
      margin-right: auto;
      margin-left: auto;
      max-width: $screen-lg;
      transition: text-shadow .18s ease;
    }
  }

  h1 {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: 15px 15px 0;
    color: #fefefe;
    color: rgba(255,255,255,.95);
    text-shadow: 0 2px 2px rgba(0,0,0,.6), 0 6px 8px rgba(0,0,0,.4);
    text-transform: uppercase;
    top: 0;
    font-size: 12px;
    @media (min-width: $screen-xs - 100) {
      font-size: 15px;
    }
    @media (min-width: $screen-xs) {
      position: static;
      line-height: 1;
      letter-spacing: -1px;
      font-size: 20px;
    }
    @media (min-width: $screen-sm) {
      font-size: 28px;
    }
    @media (min-width: $screen-md) {
      font-size: 35px;
    }
    @media (min-width: $screen-lg) {
      font-size: 48px;
    }
  }
  &:hover h1.banner-overlay-text {
    @media (min-width: $screen-md) {
      text-shadow: 0 4px 1px rgba(0,0,0,.7), 0 8px 12px rgba(0,0,0,.5);
    }
  }

  h2 {
    margin: 5px 10px;
    font-size: 11px;
    @media (min-width: $screen-xs) {
      color: #ccc;
      color: rgba(255,255,255,.7);
      font-size: 14px;
    }
    @media (min-width: $screen-sm) {
      margin-top: 5px;
      font-size: 16px;
    }
    @media (min-width: $screen-md) {
      margin-top: 8px;
      font-size: 20px;
    }
    @media (min-width: $screen-lg) {
      margin-top: 15px;
      font-size: 24px;
    }
  }


  /**
   * Banner overlay wrapper
   */
  &-overlay {

    @media (min-width: $screen-xs) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: background .36s ease;
      background: rgba(0,0,0,0);

      #header-banner:hover & {
        background: rgba(0,0,0,.1);
      }
    }
  }
}
