/**
 * League: Box variation for league page
 *
 */

.page--league {

  .league,
  .match {
    margin-bottom: 10px;
  }

  /**
   * Facebook buttons
   */
  .box-header--fb-buttons {
    padding-right: $box-gutter;
    margin-bottom: $box-gutter;
    text-align: center;

    .fb-share-button {
      margin-left: 7px;
    }

    @media (min-width: $grid-float-breakpoint) {
      position: relative;
      float: right;
      clear: both;
      margin-bottom: 0;
      bottom: -38px;
      text-align: right;

      + .box {
        > h2 {
          padding-right: 214px; // the space occupied by the fb buttons
        }
      }
    }
  }
}
