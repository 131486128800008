@font-face {
    font-family: 'footballtickettrade-2';
    src:    url('../fonts/footballtickettrade-2.eot?saok0j');
    src:    url('../fonts/footballtickettrade-2.eot?saok0j#iefix') format('embedded-opentype'),
        url('../fonts/footballtickettrade-2.ttf?saok0j') format('truetype'),
        url('../fonts/footballtickettrade-2.woff?saok0j') format('woff'),
        url('../fonts/footballtickettrade-2.svg?saok0j#footballtickettrade-2') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'footballtickettrade-2' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-envelope:before {
    content: "\e900";
}
.icon-clock:before {
    content: "\e901";
}
.icon-phone:before {
    content: "\e942";
}
.icon-search:before {
    content: "\e986";
}
.icon-cross:before {
    content: "\ea0f";
}
.icon-checkmark:before {
    content: "\ea10";
}
.icon-info:before {
    content: "\f05a";
}
.icon-caret-down:before {
    content: "\f0d7";
}
.icon-caret-up:before {
    content: "\f0d8";
}
.icon-caret-left:before {
    content: "\f0d9";
}
.icon-caret-right:before {
    content: "\f0da";
}
.icon-angle-up:before {
    content: "\f106";
}
.icon-angle-down:before {
    content: "\f107";
}

