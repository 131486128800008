/**
 * Newsletter row style
 *
 */
.newsletter {
  background: #fff;
  padding: $grid-gutter-width * 2 0;

  h3 {
    text-transform: uppercase;
  }
}