/**
 * Match: Box variation for match page
 *
 */
$tshirt--width: 40px;
$tshirt--width-sm: 80px;

.match {
  margin-top: 30px;

  /**
   * Slight variation to box default style
   */
  .box-header {
    position: relative;
    background: $brand-secondary-light;
    padding-bottom: $box-gutter;
    padding-right: $tshirt--width + 5;
    padding-left: $tshirt--width + 5;

    @media (min-width: $screen-sm) {
      padding-right: $tshirt--width-sm + 5;
      padding-left: $tshirt--width-sm + 5;
    }

    &--title {
      margin: -($box-gutter / 2) 0 0;
      color: #3F3F3F;
      font-size: 20px;
      font-style: italic;
      text-transform: none;

      small {
        font-weight: 300;
      }

      @media (min-width: $screen-sm) {
        font-size: 30px;
      }
    }

    &--subtitle {
      position: relative;
      display: inline-block;
      top: -14px;
      margin: 0 0 9px;
      padding: 7px 30px 7px 30px;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: #fff;
      font-weight: 300;
      font-size: 11px;
      font-style: italic;
      text-transform: none;

      @media (min-width: $screen-sm) {
        font-size: 14px;
      }
    }

    &--avatar {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
      width: $tshirt--width;
      // height: $tshirt--width;
      border-radius: 0;
      background-color: beige;
      border-style: solid;
      border-color: #fff;
      border-width: 0 0 0 5px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;

      @media (min-width: $screen-sm) {
        width: $tshirt--width-sm;
      }

      &:first-child {
        left: 0;
        right: auto;
        border-width: 0 5px 0 0;
      }
    }
  }

  /**
   * Details block
   */
  &--details {
    text-transform: uppercase;
    padding: $box-gutter ($box-gutter / 2);

    @media (min-width: $grid-float-breakpoint) {
      padding: $box-gutter 0;
    }

    /**
     * Details info block
     */
    .info {
      &-date {
        font-weight: bold;
      }

      &-place {
      }

      &-notes {
        color: $brand-primary;
        font-size: 11px;
      }
    }

    /**
     * Details tickets block
     */
    .tickets {
      text-align: right;
      @media (max-width: $screen-sm-max) {
        margin-top: 5px;
      }

      &-available {
        font-size: 14px;
      }

      &-from {
        font-size: 12px;
      }

      &-price {
        display: inline-block;
        margin-left: 6px;
        color: $brand-success;
        font-size: 24px;
        font-weight: 200;
      }

      .btn {
        margin: -7px 0 0 7px;
      }
    }
  }

  /**
   * Notes block
   */
  &--notes {
    background: $brand-secondary-light;
    padding: $box-gutter ($box-gutter / 2);
    margin-right: -($grid-gutter-width / 2);
    margin-left: -($grid-gutter-width / 2);
    margin-bottom: ($box-gutter / 2);
    border-top: 1px solid darken($brand-secondary-light, 5%);
    border-bottom: 1px solid darken($brand-secondary-light, 5%);

    li {
      margin: 5px;
      font-size: 110%;
    }
    .icon {
      margin-right: 5px;
    }

    @media (min-width: $grid-float-breakpoint) {
      padding: $grid-gutter-width;
    }
  }
}
