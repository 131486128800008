/**
 * Typeahead
 *
 * Custom style
 */
.tt-hint {
  color: #aaa;
}

.tt-dropdown-menu {
  max-height: 450px;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  box-shadow: 5px 5px 20px rgba(0,0,0,.1);
  text-transform: uppercase;

  // style the dataset block
  // > div {}
}

.tt-header {
  padding: 5px $grid-gutter-width;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  color: $brand-primary;
  text-transform: uppercase;
  font-size: $font-size-base * 1.2;
  font-family: $headings-font-family;
}

.tt-suggestion {
  padding: 5px $grid-gutter-width 1px;
  font-size: $font-size-base;
  cursor: pointer;

  &.tt-cursor {
    color: #fff;
    background-color: $brand-primary;
  }
}
