/**
 * never scroll horizontally
 */
body {
  overflow-x: hidden;
}

/**
 * Textarea resizable only vertically
 */
textarea {
  resize: vertical;
}

/**
 * Placeholders are italic
 */
::placeholder { font-style: italic; }
::-webkit-input-placeholder { font-style: italic; }
:-moz-placeholder { font-style: italic; }
::-moz-placeholder { font-style: italic; }
:-ms-input-placeholder { font-style: italic; }

/**
 * Undo various border-radiuses
 */
.navbar {
 @media (min-width: $grid-float-breakpoint) {
    border-radius: 0;
  }
}
.dropdown-menu {
  border-radius: 0;
}
.tooltip-inner {
  border-radius: 4px;
  padding: 4px 8px;
}
textarea.form-control,
.modal-content {
  border-radius: 10px;
}

/**
 * Button in input group is rounded as well
 */
.input-group {
  left: -13px;
  width: calc(100% + 13px);

  .form-control {
    right: -13px;
  }
  .btn {
    z-index: 2;
    border-radius: 50px !important;
  }
}

/**
 * Buttons slight modifications
 *
 */
.btn {
  text-transform: uppercase;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;

  &-lg {
    font-size: 16px;
  }
}

/**
 * Slightly change all form inputs
 */
.form-control {
  font-family: $headings-font-family;
  // text-transform: uppercase;

  .has-error & {
    background: desaturate(lighten($brand-danger, 35%), 30%);
  }
}

.control-label.required:after {
  content: "*";
  color: $brand-danger;
  padding-left: 5px;
  margin-right: -5px;
  font-size: 10px;
}
