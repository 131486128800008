/**
 * Read more style
 *
 */
.readmore {
  position: relative;

  &[data-readmore]:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 60px;
    min-height: 50px;
    height: 10%;
    // readmore block directly on top of body by default, so use the `$body-bg` color
    background-image: linear-gradient(to bottom, rgba($body-bg, 0) 0%,rgba($body-bg, 1) 100%);
    transition: bottom .1s ease;
  }

  &.expanded:after {
    bottom: -60px;
  }

  &-toggle {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      a {
        text-decoration: underline;
      }
    }

    a {
      color: $text-color;
    }

    .icon {
      font-weight: bold;
      font-size: 150%;
      padding-left: 5px;
      vertical-align: sub;
    }
  }

  /**
   * Readmore blocks specific sizes
   *
   * By default readmore will use 200px as the threshold height,
   * but we can override each block specifically through js, see the docs
   * @link(http://jedfoster.com/Readmore.js/, here)
   */
  &.about {
    max-height: 4em;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      max-height: 12em;
    }
  }

  /**
   * Readmore blocks specific sizes
   *
   */
  &.three-lines {
    max-height: 7em;
    @media (min-width: $screen-sm-min) {
      max-height: 5em;
    }
  }
}
