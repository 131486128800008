/**
 * Box header (shared style)
 */
.box-header {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: $grid-gutter-width / 2;

  @media (max-width: $screen-xs-max) {
    margin: (-$grid-gutter-width) (-$grid-gutter-width / 2) 0;
  }

  &--img {
    width: 100%;
  }

  &--wrap {
    // only actually use this when we want the fblike button on the right
    @media (min-width: $screen-xs-min) {
      position: relative;
      padding: 0 94px; // leave space to the fblike button
    }
  }

  &--title {
    font-weight: bold;
    font-size: 25px;
    margin-top: 0;

    > small {
      padding: 0 5px;
      color: $text-color;
      font-weight: bold;
    }
  }

  &--fblike {
    margin: 15px auto 5px;

    // put it on the right and vertically center it
    @media (min-width: $screen-xs-min) {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -10px; // half of the height of the fb button
    }
  }

  &--subtitle {
    font-weight: bold;
    font-size: 15px;
    color: lighten($text-color, 40%);
    margin: -5px 0 0;
  }

  &--avatar {
    width: 80px;
    display: inline-block;
    margin: -140px 7px -60px 7px;
    background: $box-bg;
    border-radius: 100%;
  }
}
