/**
 * Header menu navbar style
 *
 */
.header-navbar {
  margin-bottom: 0;
  border: 0;
  // text-transform: uppercase;
  font-size: 18px;
  font-family: $headings-font-family;

  .caret {
    color: desaturate(lighten($navbar-default-bg, 30%), 20%);
  }

  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $grid-float-breakpoint) {
      margin-left: -23px;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 16px;
  }

  /**
   * Tweak dropdowns
   */
  .dropdown-menu > li > a {
    padding: ($grid-gutter-width / 2) $grid-gutter-width;
    font-size: 16px;
    &:hover {
      background: $brand-primary;
      color: #fff;
    }
  }

  @media (max-width: $screen-xs-max) {
    min-height: 0;

    .navbar-toggle {
      position: relative;
      z-index: $zindex-navbar + 50;
      float: left;
      margin-top: -88px;
    }
  }
}
