/**
 * Notifications
 *
 */

[data-notify].alert {
  background: $header-main--bg;
  border-color: $header-main--bg;
  box-shadow: 2px 6px 10px rgba(0,0,0,.36), 5px 10px 20px rgba(0,0,0,.18);
  color: #fff;
  max-width: 330px;
  padding: 10px 30px;

  @media (max-width: $screen-xs) {
    padding: 7px 20px;
    left: 20px;
    width: auto;
  }

  .close {
    color: #fefefe;
  }
}
