/**
 * Header Top bar style
 *
 */
.header-top {
  position: relative;
  z-index: $zindex-navbar + 20;
  padding: $grid-gutter-width / 4 0;
  background: $header-top--bg;
  font-family: $headings-font-family;
  text-transform: uppercase;

  .wrap-fb {
    margin-top: 2px;
  }

  .list-inline {
    padding-right: $grid-gutter-width;
    margin: 4px 0;
    font-size: 11px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .icon {
      @extend %round_checkmark;
    }
  }

  @media (max-width: $screen-sm-max) {
    padding: $grid-gutter-width / 4 0;
    font-size: 13px;
  }
}
