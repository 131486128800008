/**
 * Bootstrap navbar expander
 * Every li contains a link, some of them have dropdown behavior on hover,
 * on click instead, a button with a caret is positioned beside them on
 * small screens.
 *
 * Layout:
 * ```
 * li.dropdown
 *   a(href='#') Link
 *   button.dropdown-toggle(data-toggle='dropdown', aria-expanded='false'): span.caret
 *     ul.dropdown-menu(role='menu')
 *       li: a(href='') Sublink
 * ```
 */
$navbar-expander--btn-caret-width: $caret-width-large;
$navbar-expander--btn-toggle-width: $navbar-padding-horizontal * 4;
$navbar-expander--btn-bg: $navbar-default-link-active-bg;
$navbar-expander--btn-hover-bg: $navbar-default-link-hover-bg;
$navbar-expander--btn-active-bg: $navbar-default-link-hover-bg;

.navbar-expander {

  li.dropdown {
    > a {
      padding-right: $navbar-padding-horizontal * 2;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding-right: $navbar-expander--btn-toggle-width;

      > a {
        padding-right: 0;
      }
    }

    // show dropdown on hover on big screens
    @media (min-width: $grid-float-breakpoint) {
      &:hover > ul.dropdown-menu {
        display: block;
      }
    }
  }

  .dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: $navbar-padding-horizontal * 2;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    // caret size (should be a bit bigger than usual)
    .dropdown-toggle .caret {
      border-top: $navbar-expander--btn-caret-width dashed;
      border-right: $navbar-expander--btn-caret-width solid transparent;
      border-left: $navbar-expander--btn-caret-width solid transparent;
    }

    // dropdown open: caret state
    .open .dropdown-toggle .caret {
      border-top: 0;
      border-bottom: $navbar-expander--btn-caret-width dashed;
      border-right: $navbar-expander--btn-caret-width solid transparent;
      border-left: $navbar-expander--btn-caret-width solid transparent;
    }

    // the dropdown toggle button
    .dropdown-toggle {
      width: $navbar-expander--btn-toggle-width;
      border-color: darken($navbar-expander--btn-bg, 2%);
      border-style: solid;
      border-width: 0 0 1px 1px;
      background: $navbar-expander--btn-bg;
    }
    .dropdown:last-child .dropdown-toggle {
      border-width: 0 0 0 1px;
    }
  }
}