/**
 * Bootstrap custom $variables
 * Override default values.
 *
 */
$icon-font-path:                           '../fonts/';
$breadcrumb-separator:                     "\25B6";
$breadcrumb-color:                         #aaa;
$font-size-base:                           13px;

// colors
$brand-primary:                            #6AB500; // green
$brand-secondary-light: #F4F4F4; // light grey
$brand-success:                            #3db900; // green
$body-bg:                                  #fff; // #F4F4F4; // grey
$btn-default-bg:                           #575757;
$btn-default-border:                       #707070;
$btn-default-color:                        #fff;

// sizes
$grid-gutter-width:                        20px;
$border-radius-base:                       50px;
$border-radius-large:                      50px;
$border-radius-small:                      50px;
$padding-base-horizontal:                  18px;
$padding-large-horizontal:                 18px;
$padding-large-vertical:                   6px;
$padding-small-horizontal:                 15px;
$padding-xs-horizontal:                    8px;

// forms
$input-bg:                                 #EAEAEA;
$input-color:                              #222;
$input-border:                             #EAEAEA;
$input-border-focus:                       darken($input-border, 5%);

// typography
$font-family-base:                         'Open Sans', Helvetica, Arial, sans-serif;
$headings-font-family:                     'Droid Sans', Helvetica, Arial, sans-serif;
$headings-font-weight:                     700;

// navbar
$navbar-height:                            48px;
$navbar-default-color:                     #fff;
$navbar-default-bg:                        $brand-primary;
$navbar-default-border:                    transparent;
$navbar-default-link-color:                #fff;
$navbar-default-link-hover-color:          #fefefe;
$navbar-default-link-hover-bg:             darken($navbar-default-bg, 3.25%);
$navbar-default-link-active-color:         #fefefe;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color:       lighten($navbar-default-bg, 40%);
$navbar-default-link-disabled-bg:          transparent;
$navbar-default-toggle-hover-bg:           icon-bar;
$navbar-default-toggle-icon-bar-bg:        #ccc;
$navbar-default-toggle-border-color:       transparent;
$caret-width-base:                         3px;

// modals
$modal-inner-padding:                      $grid-gutter-width;
$modal-title-padding:                      $grid-gutter-width;
$modal-header-border-color:                transparent;
$modal-footer-border-color:                transparent;

// pagination
$pagination-color:                     #fff !default;
$pagination-bg:                        #b3b3b3 !default;
$pagination-border:                    #b3b3b3 !default;
$pagination-hover-color:               #fff !default;
$pagination-hover-bg:                  desaturate(lighten($brand-primary, 20%), 40%) !default;
$pagination-hover-border:              $pagination-hover-bg !default;
$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;
$pagination-disabled-color:            #ccc !default;
$pagination-disabled-bg:               lighten($pagination-bg, 5%) !default;
$pagination-disabled-border:           #ddd !default;


/**
 * Bootstrap @imports
 * Import only the needed components.
 *
 */
// Core variables and mixins
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";


/**
 * Bower scss components
 * (injected through wiredep).
 *
 */
// bower:scss
// endbower


/*! @license credits */

/**
 * footballticketrade $variables and @imports
 *
 */
$brand-accent: #EF911F; // orange
$box-bg: #fff;
$box-bg-secondary: darken($box-bg, 1%); // #FCFCFC
$box-gutter: $grid-gutter-width;
$box-list-item--height: 45px;
$box-list-item--padding: 5px;
$box-title--margin-bottom: $box-gutter / 2;
$ticket-row-min-width: 70px;
$checkmarks--bg: $brand-accent;
$checkmarks--color: #fff;
$header-top--bg: $brand-secondary-light;
$header-main--bg: #4B4B4B;
$header-info--bg: $brand-secondary-light;

@import "icons";
@import "utils";
@import "utils-navbar-expander";
@import "bootstrap-tweaks";
@import "typeahead";
@import "box";
@import "box-pagination";
@import "box-list";
@import "box-header";
@import "box-content--match";
@import "content--static";
@import "content--purchase";
@import "content--event";
@import "content--ticket";
@import "content--team";
@import "content--news";
@import "page--league";
@import "page--purchase";
@import "readmore";
@import "newsletter";
@import "header";
@import "banner";
@import "modals";
@import "breadcrumbs";
@import "footer";
@import "notifications";
